import React from 'react'
import Ampersand from '../../assets/icons/ampersand.svg'
import flatMap from 'array.prototype.flatmap'

const headingMap = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
    span: 'span',
}

export default class Heading extends React.Component {
    render() {
        let { text, level, ...props } = this.props
        text = this.replaceAmpersand(text)
        text = this.replaceFullStop(text)
        text = this.replaceDoubleZ(text)
        const element = headingMap[level]

        return React.createElement(element, props, null, text)
    }

    replaceAmpersand(text) {
        let i = 0
        if (text && typeof text === 'string') {
            text = flatMap(text.split(' & '), part => {
                return [part, <Ampersand key={`heading-part-${i++}`} />]
            })

            text.pop()
        }

        return text
    }

    replaceFullStop(text) {
        let newText = []

        if (text && text instanceof Array) {
            for (let i = 0; i < text.length; i++) {
                let item = text[i]

                if (item && typeof item === 'string') {
                    if (item.includes('. ')) {
                        item = flatMap(item.split('. '), part => {
                            return [part, '.', <br />]
                        })

                        item.pop()
                        item.pop()
                    }
                }

                newText.push(item)
            }
        }

        return newText
    }

    replaceDoubleZ(text) {
        let newText = []

        if (text && text instanceof Array) {
            for (let i = 0; i < text.length; i++) {
                let item = text[i]

                if (item && typeof item === 'string') {
                    if (item.includes('zz')) {
                        item = flatMap(item.split('zz'), part => {
                            return [part, <span className="double-z">zz</span>]
                        })

                        item.pop()
                    }
                }

                newText.push(item)
            }
        }

        return newText
    }
}
