import React from 'react'

const ContactTelephoneImage = () => {
  return (
    <div className="contact-info__icon contact-info__icon--telephone">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="#0E0E0E" />
        <path
          d="M23.9995 19.9791V22.3877C24.0004 22.6113 23.9545 22.8327 23.8648 23.0375C23.775 23.2424 23.6434 23.4263 23.4783 23.5775C23.3132 23.7286 23.1183 23.8437 22.906 23.9154C22.6938 23.987 22.4689 24.0136 22.2457 23.9935C19.7702 23.725 17.3923 22.8808 15.3031 21.5286C13.3594 20.2959 11.7114 18.6512 10.4763 16.7113C9.11669 14.6168 8.27059 12.2321 8.00653 9.75036C7.98643 9.52834 8.01286 9.30457 8.08416 9.0933C8.15546 8.88203 8.27005 8.6879 8.42065 8.52325C8.57124 8.35861 8.75454 8.22706 8.95886 8.13699C9.16319 8.04691 9.38407 8.00029 9.60744 8.00008H12.0209C12.4113 7.99624 12.7898 8.13422 13.0858 8.3883C13.3818 8.64237 13.5752 8.99521 13.6298 9.38103C13.7317 10.1519 13.9206 10.9087 14.1929 11.6371C14.3012 11.9245 14.3246 12.2368 14.2604 12.5371C14.1963 12.8373 14.0472 13.1129 13.8309 13.3312L12.8092 14.3509C13.9545 16.3609 15.6221 18.0252 17.6361 19.1682L18.6578 18.1485C18.8765 17.9326 19.1527 17.7839 19.4535 17.7198C19.7544 17.6558 20.0673 17.6792 20.3552 17.7872C21.0851 18.059 21.8435 18.2476 22.6158 18.3492C23.0066 18.4042 23.3635 18.6007 23.6186 18.9012C23.8737 19.2017 24.0093 19.5853 23.9995 19.9791Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default ContactTelephoneImage
