import React, { useContext } from 'react'
import { AltogetherItalianImage } from './altogether-italian-image'
import { LanguageContext } from 'crostamollica-components/context'
import { translations } from 'crostamollica-components/utils'

export const AltogetherItalian = () => {
  const { language } = useContext(LanguageContext)
  const messages = translations(language)

  return (
    <section className="altogether-italian">
      <div className="altogether-italian__container container">
        <figure className="altogether-italian__logo">
          <div className="altogether-italian__stripe"></div>
          <AltogetherItalianImage language={language} />
          <figcaption>
            <span>Altogether Italian</span>
          </figcaption>
        </figure>
      </div>
    </section>
  )
}
