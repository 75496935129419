import React from 'react'

export const BigArrowRight = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="28.5" r="27.5" stroke="#0E0E0E" strokeWidth="2" />
      <path
        d="M22 29H36"
        stroke="#0E0E0E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 22L36 29L29 36"
        stroke="#0E0E0E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
