import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

class HeaderNavigationLinks extends Component {
  render() {
    return null
    // return (
    //   <StaticQuery
    //     query={graphql`
    //       query HeaderNavigationLinksQuery {
    //         about: prismicAboutPage {
    //           data {
    //             meta_navigation_title
    //           }
    //         }
    //         whereToBuy: prismicWhereToBuyPage {
    //           data {
    //             meta_navigation_title
    //           }
    //         }
    //         recipes: prismicRecipesIndexPage {
    //           data {
    //             meta_navigation_title
    //           }
    //         }
    //         journal: prismicJournalIndexPage {
    //           data {
    //             meta_navigation_title
    //           }
    //         }
    //       }
    //     `}
    //     field={data => (
    //       <>
    //         {[data.about].map((page, index) => {
    //           return (
    //             <li className="nav__list-item" key={index}>
    //               <Link
    //                 to="/about"
    //                 className="nav__link"
    //                 activeClassName="nav__link--active"
    //               >
    //                 {page.data.meta_navigation_title}
    //               </Link>
    //             </li>
    //           )
    //         })}
    //         {[data.whereToBuy].map((page, index) => {
    //           return (
    //             <li className="nav__list-item" key={index}>
    //               <Link
    //                 to="/where-to-buy"
    //                 className="nav__link"
    //                 activeClassName="nav__link--active"
    //               >
    //                 {page.data.meta_navigation_title}
    //               </Link>
    //             </li>
    //           )
    //         })}
    //         {[data.recipes].map((page, index) => {
    //           return (
    //             <li className="nav__list-item" key={index}>
    //               <Link
    //                 to="/recipes"
    //                 className="nav__link"
    //                 activeClassName="nav__link--active"
    //               >
    //                 {page.data.meta_navigation_title}
    //               </Link>
    //             </li>
    //           )
    //         })}
    //         {/*{[data.journal].map((page, index) => {*/}
    //         {/*  return (*/}
    //         {/*    <li className="nav__list-item" key={index}>*/}
    //         {/*      <Link*/}
    //         {/*        to="/journal"*/}
    //         {/*        className="nav__link"*/}
    //         {/*        activeClassName="nav__link--active"*/}
    //         {/*      >*/}
    //         {/*        {page.data.meta_navigation_title}*/}
    //         {/*      </Link>*/}
    //         {/*    </li>*/}
    //         {/*  )*/}
    //         {/*})}*/}
    //       </>
    //     )}
    //   />
    // )
  }
}
export default HeaderNavigationLinks
