import React, { useContext, useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { PageHeader } from 'crostamollica-components/components'
import { TwoImageTextBlock } from 'crostamollica-components/components'
import { GatsbyLink } from 'crostamollica-components/utils'
import { GalleryAndReviews } from 'crostamollica-components/components'
import { GiantText } from 'crostamollica-components/components'
import { FeaturedProductsHomeAlt } from '../components/featured-products/featured-products-alt'
import { AltogetherItalian } from '../components/content/altogether-italian'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Hreflang, ScrollingBanner } from 'crostamollica-components/components'
import GetInTouch from '../components/get-in-touch'
import WhereToBuy from '../components/where-to-buy'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { LanguageContext } from 'crostamollica-components/context'

const IndexPage = ({ data, location }) => {
  const { language, setLanguage } = useContext(LanguageContext)
  const [largeTextGraphic, setLargeTextGraphic] = useState(null)
  const isMobile = useIsMobile(false)

  setLanguage(data?.prismicHomepage?.lang)

  const nodes = data?.allPrismicLargeTextGraphic?.nodes
  let textGraphics = []
  let i;
  for (i in data?.allPrismicLargeTextGraphic?.nodes) {
    if (data?.allPrismicLargeTextGraphic?.nodes[i].lang === language) {
      textGraphics.push(data?.allPrismicLargeTextGraphic?.nodes[i])
    }
  }

  useEffect(() => {
    if (textGraphics && textGraphics.length > 0) {
      setLargeTextGraphic(
        textGraphics[Math.floor(Math.random() * textGraphics.length)].data.svg,
      )
    }
  }, [nodes])

  const pageData = data

  const getInTouchData = {
    title: pageData.prismicHomepage.data.get_in_touch_heading,
    content: pageData.prismicHomepage.data.get_in_touch_content,
  }

  const whereToBuyData = {
    title: pageData.prismicHomepage.data.where_to_buy_heading,
    content: pageData.prismicHomepage.data.where_to_buy_content,
    images: pageData.prismicHomepage.data.where_to_buy_images,
  }

  const featuredProducts = pageData?.prismicHomepage.data.featured_products
  const productData =
    featuredProducts[0]?.featured_product?.document?.data
      ?.featured_product_groups

  const chunks = function (array, size) {
    if (!Array.isArray(array)) {
      return []
    }

    var results = []
    var orig = [...array]
    while (orig.length) {
      results.push(orig.splice(0, size))
    }
    return results
  }

  return (
    <>
      <Hreflang />

      <Seo data={pageData.prismicHomepage.data} />

      <PageHeader
        video={pageData?.prismicHomepage.data.main_video_link?.url}
        location={location}
        heading={pageData.prismicHomepage.data.main_title}
        image={pageData.prismicHomepage.data.main_image}
        link={
          pageData.main_link ? (
            <GatsbyLink
              element={pageData.prismicHomepage.data.main_link}
              content={pageData.prismicHomepage.data.main_link_text}
              className="cta-link"
            />
          ) : undefined
        }
      />

      <div className="pseudo-elements">
        <TwoImageTextBlock
          heading={pageData.prismicHomepage.data.our_story_heading}
          subHeading={pageData.prismicHomepage.data.our_story_sub_heading}
          content={pageData.prismicHomepage.data.our_story_content}
          imageOne={pageData.prismicHomepage.data.our_story_image_01}
          imageTwo={pageData.prismicHomepage.data.our_story_image_02}
          link={
            pageData.prismicHomepage.data.our_story_link ? (
              <GatsbyLink
                element={pageData.prismicHomepage.data.our_story_link}
                content={pageData.prismicHomepage.data.our_story_link_text}
                className="cta-link"
              />
            ) : undefined
          }
        />
      </div>

      <ScrollingBanner />

      {productData && (
        <FeaturedProductsHomeAlt
          products={
            isMobile
              ? chunks(productData.slice(0, 4), 1)
              : chunks(productData, 6)
          }
          title={
            pageData.prismicFeaturedProducts?.data?.featured_products_title
          }
          id="featured_products_home"
        />
      )}

      <WhereToBuy data={whereToBuyData} />

      <AltogetherItalian />

      <TwoImageTextBlock
        heading={pageData.prismicHomepage.data.our_manifesto_heading}
        subHeading={pageData.prismicHomepage.data.our_manifesto_sub_heading}
        content={pageData.prismicHomepage.data.our_manifesto_content}
        imageOne={pageData.prismicHomepage.data.our_manifesto_image_01}
        imageTwo={pageData.prismicHomepage.data.our_manifesto_image_02}
        link={
          pageData.our_manifesto_link ? (
            <GatsbyLink
              element={pageData.prismicHomepage.data.our_manifesto_link}
              content={pageData.prismicHomepage.data.our_manifesto_link_text}
              className="cta-link"
            />
          ) : undefined
        }
      />

      <div className="large-text-bolli">
        {largeTextGraphic && <GiantText content={largeTextGraphic} />}
      </div>

      <GalleryAndReviews
        reviews={pageData.prismicHomepage.data.reviews}
        photoGallery={pageData.prismicHomepage.data.photo_gallery}
      />

      <GetInTouch data={getInTouchData} />
    </>
  )
}

export const query = graphql`
  query HomepageQuery($id: String!, $lang: String!) {
    prismicHomepage(id: { eq: $id }) {
      _previewable
      lang
      data {
        main_image {
          ...Image
        }
        main_title {
          ...RichText
        }
        main_link {
          ...Link
        }
        main_video_link {
          ...Link
        }
        main_link_text
        our_story_content {
          ...RichText
        }
        our_story_heading {
          ...RichText
        }
        our_story_sub_heading {
          ...RichText
        }
        our_story_link {
          ...Link
        }
        our_story_link_text
        our_story_image_01 {
          ...Image
        }
        our_story_image_02 {
          ...Image
        }
        our_manifesto_content {
          ...RichText
        }
        our_manifesto_heading {
          ...RichText
        }
        our_manifesto_sub_heading {
          ...RichText
        }
        our_manifesto_link {
          ...Link
        }
        our_manifesto_link_text
        our_manifesto_image_01 {
          ...Image
        }
        our_manifesto_image_02 {
          ...Image
        }
        photo_gallery {
          cta_image {
            ...Image
          }
          cta_link {
            ...Link
          }
          cta_link_text
          cta_title {
            ...RichText
          }
          photo_desktop {
            ...Image
          }
          photo_mobile {
            ...Image
          }
          photo_credit
          photo_credit_link
          photo_title {
            ...RichText
          }
          cta_position_desktop_left
          cta_position_desktop_top
          cta_position_mobile_left
          cta_position_mobile_top
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        languages {
          locale
          link {
            ...Link
          }
        }
        reviews {
          review {
            uid
            document {
              ... on PrismicReview {
                uid
                data {
                  name {
                    ...RichText
                  }
                  review {
                    ...RichText
                  }
                  stars
                }
              }
            }
          }
        }
        where_to_buy_heading
        where_to_buy_content {
          ...RichText
        }
        where_to_buy_images {
          stockist_image {
            ...Image
          }
        }
        get_in_touch_heading
        get_in_touch_content {
          ...RichText
        }
        featured_products {
          featured_product {
            document {
              ... on PrismicFeaturedProducts {
                id
                data {
                  featured_products_title
                  featured_product_groups {
                    product_title
                    new_product
                    background_image {
                      ...Image
                    }
                    product_image {
                      ...Image
                    }
                    product_description {
                      ...RichText
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicLargeTextGraphic(
      filter: {
        lang: { eq: $lang }
        data: { appears_on_homepage: { eq: true } }
      }
    ) {
      nodes {
        lang
        data {
          svg {
            ...Image
          }
        }
      }
    }
  }
`

export default withPrismicPreview(IndexPage)
