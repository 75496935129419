import React, { Component } from 'react'
import { Link } from 'gatsby'

import MenuToggle from './nav/menu-toggle'
import LegalPageNavigation from './legal-page-navigation'
import { bind, debounce } from 'decko'
import HeaderNavigationLinks from './header-navigation-links'
import { Social } from 'crostamollica-components/components'
import { NavOpenContext } from './context/nav-open-context'

export default class HeaderNavigation extends Component {
  /**
   * @type {string}
   */
  id = 'main-nav'

  /**
   * @type {object}
   */
  state = {
    mobile: false,
  }

  componentDidMount() {
    this.setMobileState()

    window.addEventListener('resize', this.setMobileState, { passive: true })
  }

  @bind
  @debounce(100)
  setMobileState() {
    this.setState({
      mobile: !window.matchMedia('(min-width: 63.75em)').matches,
    })
  }

  render() {
    return (
      <NavOpenContext.Consumer>
        {({ navOpen, toggleNav }) => (
          <nav
            className="header__nav nav"
            id={this.id}
            aria-hidden={this.state.mobile ? !navOpen[this.id] : true}
            {...(this.state.mobile && navOpen['product-subnav']
              ? { 'aria-activedescendant': 'product-subnav' }
              : {})}
          >
            <ul className="nav__list">
              <HeaderNavigationLinks />
            </ul>

            {this.state.mobile && (
              <ul className="nav__list nav__list--secondary">
                <LegalPageNavigation />
              </ul>
            )}

            {this.state.mobile && <Social className="nav__social" />}
          </nav>
        )}
      </NavOpenContext.Consumer>
    )
  }
}
