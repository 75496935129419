import React, { useContext } from 'react'
import { graphql } from 'gatsby'

import Seo from '@superrb/gatsby-addons/src/components/seo'
import { PageHeader } from 'crostamollica-components/components'
import Faqs from '../components/faqs/faqs'
import ContactForm from '../components/contact/contact-form'
import { Hreflang, FaqsIntro } from 'crostamollica-components/components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { useAlternates } from 'crostamollica-components/hooks'
import { LanguageContext } from 'crostamollica-components/context'

const ContactPage = ({ data, location, pageContext }) => {
  const pageData = data.prismicContactPage.data
  const crumbs = pageContext?.breadcrumb?.crumbs
  const { setLanguage } = useContext(LanguageContext)
  setLanguage(data?.prismicContactPage?.lang)

  useAlternates(data?.prismicContactPage?.data?.languages)
  const { language } = useContext(LanguageContext)

  return (
    <>
      <Hreflang />

      <Seo data={pageData} />

      <PageHeader
        location={location}
        heading={pageData.main_title}
        title={pageData.meta_navigation_title}
        image={pageData.main_image}
        text={pageData.main_description}
        crumbs={crumbs}
      />

      <div className="contact-faq">
        <FaqsIntro
          heading={pageData.faqs_intro_heading}
          text={pageData.faqs_intro_text}
        />
        <Faqs />
      </div>
      <ContactForm language={language} />
    </>
  )
}

export const query = graphql`
  query ContactPageQuery($id: String!) {
    prismicContactPage(id: { eq: $id }) {
      _previewable
      lang
      data {
        main_image {
          ...Image
        }
        main_title {
          ...RichText
        }
        main_description {
          ...RichText
        }
        faqs_intro_heading {
          ...RichText
        }
        faqs_intro_text {
          ...RichText
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        languages {
          locale
          link {
            ...Link
          }
        }
      }
    }
  }
`

export default withPrismicPreview(ContactPage)
