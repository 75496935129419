import React from 'react'

const ContactEmailImage = () => {
  return (
    <div className="contact-info__icon contact-info__icon--email">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="#0E0E0E" />
        <rect
          x="8"
          y="10"
          width="16"
          height="12"
          rx="2"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M8 13L15.1056 16.5528C15.6686 16.8343 16.3314 16.8343 16.8944 16.5528L24 13"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default ContactEmailImage
