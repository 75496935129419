import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import Seo from '@superrb/gatsby-addons/src/components/seo'
import { RichText } from '@superrb/gatsby-addons/components'
import LegalPageNavigation from '../components/legal-page-navigation'
import {
  Breadcrumbs,
  Heading,
  Hreflang,
} from 'crostamollica-components/components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { useAlternates } from 'crostamollica-components/hooks'
import { LanguageContext } from 'crostamollica-components/context'

const LegalPageTemplate = ({ data, location, pageContext }) => {
  const pageData = data.prismicLegalPage.data
  const crumbs = pageContext?.breadcrumb?.crumbs
  const { setLanguage } = useContext(LanguageContext)
  setLanguage(data?.prismicLegalPage?.lang)

  useAlternates(data?.prismicLegalPage?.data?.languages)

  return (
    <>
      <Hreflang />

      <Seo data={pageData} />

      <section className="container">
        <div className="legal-page-header">
          <div className="legal-page">
            {crumbs && (
              <Breadcrumbs
                crumbs={crumbs}
                crumbLabel={
                  pageData.meta_navigation_title
                    ? pageData.meta_navigation_title
                    : pageData.page_title.text
                }
              />
            )}

            <div className="legal-page__container">
              <div className="legal-page__header">
                <Heading text={pageData.page_title.text} level="1" />

                <ul>
                  <LegalPageNavigation />
                </ul>
              </div>

              <hr />

              <div className="legal-page__content">
                <RichText field={pageData.page_content.richText} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  query LegalPageQuery($id: String!) {
    prismicLegalPage(id: { eq: $id }) {
      _previewable
      lang
      data {
        page_content {
          ...RichText
        }
        page_title {
          ...RichText
        }
        meta_description
        meta_robots
        meta_title
        meta_navigation_title
        meta_image {
          ...Image
        }
        languages {
          locale
          link {
            ...Link
          }
        }
      }
    }
  }
`

export default withPrismicPreview(LegalPageTemplate)
