import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  InstagramFeed,
  Newsletter,
  RegionSelector,
  Social,
} from 'crostamollica-components/components'
import { LogoImage } from 'crostamollica-components/assets'
import FooterNavigation from './footer-navigation'
import { translations } from 'crostamollica-components/utils'
import { LanguageContext } from 'crostamollica-components/context'

const Footer = ({ siteTitle }) => {
  const { language } = useContext(LanguageContext)
  const messages = translations(language)

  return (
    <footer className="footer">
      <div className="footer__main">
        <InstagramFeed />

        <div className="footer__container footer__container--credentials container container--flex">
          <RegionSelector />
          <FooterNavigation />

          <span className="footer__tagline">
            {messages.footer.made_in_italy}
          </span>

          <div className="footer__bottom">
            <span className="footer__copyright">
              &copy;{new Date().getFullYear()} {siteTitle}.
            </span>

            <span className="footer__attribution">
              <span
                dangerouslySetInnerHTML={{
                  __html: messages.footer.website_by_superrb,
                }}
              />
            .
          </span>
          </div>
        </div>
      </div>

      <div className="footer__logo footer-logo">
        <LogoImage />
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
