import React, { useContext } from 'react'
import { translations } from 'crostamollica-components/utils'
import { LanguageContext } from 'crostamollica-components/context'

export const InvalidEmailMessage = () => {
  const { language } = useContext(LanguageContext)
  const messages = translations(language)

  return (
    <span className="contact-form__required">
      {messages.contact_form.errors.invalid_email}
    </span>
  )
}
