import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import LegalPageNavigation from './legal-page-navigation'

const FooterNavigation = () => {
  return (
    <nav className="footer__nav nav">
      <ul className="nav__list">
        <LegalPageNavigation />
      </ul>
    </nav>
  )
}
export default FooterNavigation
