import React, { Component } from 'react'
import { RequiredMessage } from './required-message'

export class ContactInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: false,
    }
  }

  setFocus = () => {
    this.inputRef.focus()
  }

  onBlur = () => {
    if (this.props.value === '') {
      this.setState({
        selected: false,
      })
    }
  }

  setSelected = () => {
    this.setState({
      selected: true,
    })
  }

  render() {
    const wrapperClass = `contact-form__field ${
      this.props.failed ? 'failed' : ''
    } ${this.state.selected ? 'selected' : ''} 
    ${this.props.type ? this.props.type : ''}`

    return (
      <>
        <div className={wrapperClass} onClick={this.setFocus}>
          <label className="contact-form__label" htmlFor={this.props.id}>
            {this.props.label}
          </label>
          <input
            autoComplete="off"
            className="contact-form__input"
            type={this.props.type ? this.props.type : "text"}
            name={this.props.id}
            id={this.props.id}
            required={true}
            onChange={event => this.props.onChange(event.currentTarget.value)}
            value={this.props.value}
            ref={ref => (this.inputRef = ref)}
            onFocus={this.setSelected}
            onBlur={this.onBlur}
          />
        </div>

        {this.props.failed && <RequiredMessage />}
      </>
    )
  }
}
