/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { linkResolver } from './linkResolver'

import IndexPage from '../templates/homepage-template'
import Contact from '../templates/contact-page-template'
import LegalPageTemplate from '../templates/legal-page-template'

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_API_REPOSITORY_NAME,
    linkResolver,
    componentResolver: {
      homepage: IndexPage,
      contact_page: Contact,
      legal_page: LegalPageTemplate,
    },
  },
]
