import React from 'react'

export const AltogetherItalianImage = ({
  language = process.env.GATSBY_LANGUAGE,
}) => {
  switch (language) {
    case 'fr':
    case 'fr-fr':
    case 'fr-be':
      return (
        <svg
          width="450"
          height="162"
          viewBox="0 -3 450 159"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M378.198 149.705H61.3301V159H378.198V149.705Z"
            fill="black"
          />
          <path
            d="M424.667 114.081C426.093 114.096 428.963 114.766 429.8 118.214C431.765 126.294 414.407 133.661 414.407 133.661L417.462 137.473C436.182 128.518 449.877 115.164 449.994 97.7161C450.101 82.5262 439.948 70.3763 423.65 71.1193C411.24 71.6874 401.642 81.7298 402.216 93.5787C402.766 104.84 412.417 114.008 424.667 114.081V114.081Z"
            fill="black"
          />
          <path
            d="M23.8128 23.515C20.9669 23.6072 18.8945 23.1605 17.8534 19.9555C15.382 12.3751 30.0593 3.48359 30.0593 3.48359L27.8166 0.623352C10.6096 10.4181 -1.10013 24.2045 0.0820287 40.8609C1.11824 55.3515 11.4074 64.3644 23.7788 64.5878C37.5026 64.8306 47.5193 53.7782 45.9674 41.5699C44.6053 30.8914 34.8901 23.0779 23.8128 23.5101V23.515Z"
            fill="black"
          />
          <path
            d="M337.559 110.274C337.559 106.841 337.899 103.359 338.575 99.8139C339.252 96.2739 340.463 93.0591 342.205 90.1746C343.946 87.2852 346.32 84.9349 349.327 83.109C352.333 81.2831 356.167 80.375 360.827 80.375C365.488 80.375 369.321 81.2879 372.328 83.109C375.334 84.9349 377.708 87.2852 379.45 90.1746C381.192 93.0591 382.398 96.2739 383.079 99.8139C383.755 103.354 384.096 106.836 384.096 110.274C384.096 113.702 383.755 117.189 383.079 120.729C382.403 124.269 381.192 127.484 379.45 130.368C377.708 133.258 375.339 135.608 372.328 137.434C369.321 139.26 365.488 140.173 360.827 140.173C356.167 140.173 352.333 139.26 349.327 137.434C346.32 135.608 343.946 133.258 342.205 130.368C340.463 127.484 339.256 124.274 338.575 120.729C337.899 117.189 337.559 113.707 337.559 110.274V110.274ZM350.83 110.274C350.83 111.799 350.903 113.62 351.049 115.747C351.195 117.869 351.594 119.913 352.241 121.87C352.888 123.832 353.88 125.493 355.203 126.853C356.532 128.217 358.405 128.897 360.822 128.897C363.182 128.897 365.04 128.217 366.398 126.853C367.755 125.493 368.757 123.832 369.404 121.87C370.051 119.908 370.45 117.869 370.596 115.747C370.742 113.625 370.815 111.799 370.815 110.274C370.815 108.749 370.742 106.923 370.596 104.801C370.45 102.679 370.051 100.635 369.404 98.6728C368.752 96.7109 367.75 95.055 366.398 93.6904C365.04 92.3307 363.182 91.646 360.822 91.646C358.405 91.646 356.532 92.3258 355.203 93.6904C353.875 95.0501 352.888 96.7109 352.241 98.6728C351.594 100.635 351.195 102.674 351.049 104.801C350.903 106.923 350.83 108.749 350.83 110.274V110.274Z"
            fill="black"
          />
          <path
            d="M286.924 123.133C285.917 125.872 284.477 128.703 280.92 128.703C277.622 128.703 275.856 126.683 272.509 122.472C272.548 122.735 255.122 100.183 255.122 100.183C258.732 97.5559 262.609 95.2395 262.609 90.0483C262.609 84.4881 258.172 80.5984 250.349 80.5984C242.566 80.5984 234.699 85.2117 234.699 93.3602C234.699 99.1001 237.92 103.131 240.625 106.612L257.579 128.455C261.558 133.306 265.611 137.745 272.631 137.745C280.585 137.745 286.753 130.15 288.612 123.687L286.924 123.128V123.133ZM247.397 87.868C247.397 85.2117 249.26 83.109 253.57 83.109C257.734 83.109 260.926 86.1003 260.926 89.9707C260.926 94.7928 257.486 96.7303 254.066 98.8136C249.829 93.3262 247.392 89.8153 247.392 87.868H247.397Z"
            fill="black"
          />
          <path
            d="M274.524 111.813C274.996 111.823 275.974 112.046 276.251 113.212C276.923 115.951 271.041 118.447 271.041 118.447L272.077 119.738C278.416 116.703 283.052 112.182 283.091 106.268C283.13 101.125 279.691 97.0071 274.174 97.2596C269.971 97.4539 266.716 100.853 266.911 104.874C267.091 108.686 270.365 111.789 274.519 111.813H274.524Z"
            fill="black"
          />
          <path
            d="M237.449 118.287C236.067 118.34 235.065 118.117 234.559 116.563C233.362 112.887 240.484 108.569 240.484 108.569L239.395 107.185C231.046 111.935 225.364 118.622 225.938 126.707C226.435 133.743 231.436 138.109 237.429 138.216C244.094 138.337 248.959 132.966 248.205 127.052C247.543 121.861 242.829 118.068 237.453 118.282L237.449 118.287Z"
            fill="black"
          />
          <path
            d="M61.4277 137.832V82.3563H73.3467V137.832H61.4277V137.832Z"
            fill="black"
          />
          <path
            d="M102.313 93.3893V137.827H90.0823V93.3893H79.832V82.3514H113.614L113.001 93.3893H102.317H102.313Z"
            fill="black"
          />
          <path
            d="M128.436 131.364L125.784 137.832H112.07L137.825 80.3798L163.516 137.832H149.807L147.214 131.364H128.436ZM137.825 108.249L132.576 121.069H143.016L137.825 108.249V108.249Z"
            fill="black"
          />
          <path
            d="M201.372 137.832H169.998V82.3563H181.859V127.785H201.985L201.372 137.832Z"
            fill="black"
          />
          <path
            d="M208.473 137.832V82.3563H220.392V137.832H208.473Z"
            fill="black"
          />
          <path
            d="M330.864 139.566L303.319 112.192V137.832H291.4V80.3798L318.95 109.356V82.23H330.864V139.566Z"
            fill="black"
          />
          <path
            d="M136.949 26.2198V70.6579H124.719V26.2198H114.469V15.1819H148.25L147.637 26.2198H136.954H136.949Z"
            fill="black"
          />
          <path
            d="M264.57 26.2198V70.6579H252.34V26.2198H242.09V15.1819H275.871L275.258 26.2198H264.575H264.57Z"
            fill="black"
          />
          <path
            d="M223.447 26.2198V70.6579H211.217V26.2198H200.967V15.1819H234.748L234.135 26.2198H223.452H223.447Z"
            fill="black"
          />
          <path
            d="M279.555 42.9248C279.555 39.4915 279.895 36.0097 280.571 32.4648C281.248 28.9247 282.459 25.7099 284.201 22.8254C285.942 19.936 288.316 17.5857 291.323 15.7598C294.329 13.9339 298.163 13.0258 302.823 13.0258C307.484 13.0258 311.317 13.9388 314.324 15.7598C317.33 17.5857 319.704 19.9409 321.446 22.8254C323.188 25.7099 324.394 28.9247 325.075 32.4648C325.751 36.0049 326.092 39.4867 326.092 42.9248C326.092 46.3532 325.751 49.8399 325.075 53.38C324.399 56.9201 323.188 60.1348 321.446 63.0193C319.704 65.9087 317.335 68.259 314.324 70.0849C311.317 71.9108 307.484 72.8238 302.823 72.8238C298.163 72.8238 294.329 71.9108 291.323 70.0849C288.316 68.259 285.942 65.9087 284.201 63.0193C282.459 60.1348 281.253 56.9201 280.571 53.38C279.895 49.8399 279.555 46.358 279.555 42.9248ZM292.826 42.9248C292.826 44.4496 292.899 46.2706 293.045 48.3976C293.191 50.5197 293.59 52.5641 294.237 54.5211C294.884 56.483 295.876 58.1438 297.2 59.5035C298.528 60.8681 300.401 61.5479 302.818 61.5479C305.178 61.5479 307.036 60.8681 308.394 59.5035C309.751 58.1438 310.753 56.483 311.4 54.5211C312.047 52.5593 312.446 50.5197 312.592 48.3976C312.738 46.2706 312.811 44.4496 312.811 42.9248C312.811 41.4 312.738 39.5741 312.592 37.452C312.446 35.3299 312.047 33.2854 311.4 31.3236C310.748 29.3617 309.746 27.7058 308.394 26.3412C307.036 24.9815 305.178 24.2968 302.818 24.2968C300.401 24.2968 298.528 24.9767 297.2 26.3412C295.871 27.7009 294.884 29.3617 294.237 31.3236C293.59 33.2854 293.191 35.325 293.045 37.452C292.899 39.5741 292.826 41.4 292.826 42.9248V42.9248Z"
            fill="black"
          />
          <path
            d="M174.472 70.5997C162.315 70.5997 154.74 61.7713 154.74 48.8249V15.2451H166.474V47.7615C166.474 53.5499 168.265 58.8771 174.535 58.8771C180.869 58.8771 182.562 53.5499 182.562 47.7615V15.2451H194.394V48.8249C194.394 61.7713 186.556 70.5997 174.472 70.5997Z"
            fill="black"
          />
        </svg>
      )
      break

    case 'nl':
    case 'nl-nl':
    case 'nl-be':
      return (
        <svg
          width="450"
          height="159"
          viewBox="0 0 450 159"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M378.198 149.705H61.3301V159H378.198V149.705Z"
            fill="black"
          />
          <path
            d="M424.667 114.081C426.093 114.096 428.963 114.766 429.8 118.214C431.765 126.294 414.407 133.661 414.407 133.661L417.462 137.473C436.182 128.518 449.877 115.164 449.994 97.716C450.101 82.5262 439.948 70.3762 423.65 71.1192C411.24 71.6874 401.642 81.7298 402.216 93.5786C402.766 104.84 412.417 114.008 424.667 114.081Z"
            fill="black"
          />
          <path
            d="M23.8128 23.5149C20.9669 23.6072 18.8945 23.1604 17.8534 19.9554C15.382 12.375 30.0593 3.48353 30.0593 3.48353L27.8166 0.623291C10.6096 10.418 -1.10013 24.2045 0.0820287 40.8609C1.11824 55.3515 11.4074 64.3644 23.7788 64.5878C37.5026 64.8306 47.5193 53.7781 45.9674 41.5699C44.6053 30.8913 34.8901 23.0779 23.8128 23.5101V23.5149Z"
            fill="black"
          />
          <path
            d="M337.559 110.274C337.559 106.841 337.899 103.359 338.575 99.8139C339.252 96.2738 340.463 93.0591 342.205 90.1745C343.946 87.2852 346.32 84.9348 349.327 83.1089C352.333 81.283 356.167 80.3749 360.827 80.3749C365.488 80.3749 369.321 81.2879 372.328 83.1089C375.334 84.9348 377.708 87.2852 379.45 90.1745C381.192 93.0591 382.398 96.2738 383.079 99.8139C383.755 103.354 384.096 106.836 384.096 110.274C384.096 113.702 383.755 117.189 383.079 120.729C382.403 124.269 381.192 127.484 379.45 130.368C377.708 133.258 375.339 135.608 372.328 137.434C369.321 139.26 365.488 140.173 360.827 140.173C356.167 140.173 352.333 139.26 349.327 137.434C346.32 135.608 343.946 133.258 342.205 130.368C340.463 127.484 339.256 124.274 338.575 120.729C337.899 117.189 337.559 113.707 337.559 110.274ZM350.83 110.274C350.83 111.799 350.903 113.62 351.049 115.747C351.195 117.869 351.594 119.913 352.241 121.87C352.888 123.832 353.88 125.493 355.203 126.853C356.532 128.217 358.405 128.897 360.822 128.897C363.182 128.897 365.04 128.217 366.398 126.853C367.755 125.493 368.757 123.832 369.404 121.87C370.051 119.908 370.45 117.869 370.596 115.747C370.742 113.625 370.815 111.799 370.815 110.274C370.815 108.749 370.742 106.923 370.596 104.801C370.45 102.679 370.051 100.635 369.404 98.6727C368.752 96.7108 367.75 95.0549 366.398 93.6903C365.04 92.3306 363.182 91.6459 360.822 91.6459C358.405 91.6459 356.532 92.3258 355.203 93.6903C353.875 95.0501 352.888 96.7108 352.241 98.6727C351.594 100.635 351.195 102.674 351.049 104.801C350.903 106.923 350.83 108.749 350.83 110.274Z"
            fill="black"
          />
          <path
            d="M286.924 123.133C285.917 125.872 284.477 128.703 280.92 128.703C277.622 128.703 275.856 126.683 272.509 122.472C272.548 122.735 255.122 100.183 255.122 100.183C258.732 97.5557 262.609 95.2394 262.609 90.0482C262.609 84.488 258.172 80.5983 250.349 80.5983C242.566 80.5983 234.699 85.2116 234.699 93.3601C234.699 99.1 237.92 103.131 240.625 106.612L257.579 128.455C261.558 133.306 265.611 137.745 272.631 137.745C280.585 137.745 286.753 130.15 288.612 123.686L286.924 123.128V123.133ZM247.397 87.8678C247.397 85.2116 249.26 83.1089 253.57 83.1089C257.734 83.1089 260.926 86.1002 260.926 89.9705C260.926 94.7926 257.486 96.7302 254.066 98.8135C249.829 93.3261 247.392 89.8151 247.392 87.8678H247.397Z"
            fill="black"
          />
          <path
            d="M274.524 111.813C274.996 111.823 275.974 112.046 276.251 113.212C276.923 115.951 271.041 118.447 271.041 118.447L272.077 119.738C278.416 116.703 283.052 112.182 283.091 106.268C283.13 101.125 279.691 97.007 274.174 97.2596C269.971 97.4538 266.716 100.853 266.911 104.874C267.091 108.686 270.365 111.789 274.519 111.813H274.524Z"
            fill="black"
          />
          <path
            d="M237.449 118.286C236.067 118.34 235.065 118.116 234.559 116.563C233.362 112.886 240.484 108.569 240.484 108.569L239.395 107.185C231.046 111.935 225.364 118.622 225.938 126.707C226.435 133.743 231.436 138.109 237.429 138.216C244.094 138.337 248.959 132.966 248.205 127.052C247.543 121.861 242.829 118.068 237.453 118.282L237.449 118.286Z"
            fill="black"
          />
          <path
            d="M61.4277 137.832V82.3562H73.3467V137.832H61.4277Z"
            fill="black"
          />
          <path
            d="M102.313 93.3892V137.827H90.0823V93.3892H79.832V82.3513H113.614L113.001 93.3892H102.317H102.313Z"
            fill="black"
          />
          <path
            d="M128.436 131.364L125.784 137.832H112.07L137.825 80.3798L163.516 137.832H149.807L147.214 131.364H128.436ZM137.825 108.249L132.576 121.069H143.016L137.825 108.249Z"
            fill="black"
          />
          <path
            d="M201.372 137.832H169.998V82.3562H181.859V127.785H201.985L201.372 137.832Z"
            fill="black"
          />
          <path
            d="M208.473 137.832V82.3562H220.392V137.832H208.473Z"
            fill="black"
          />
          <path
            d="M330.864 139.566L303.319 112.192V137.832H291.4V80.3798L318.95 109.356V82.2299H330.864V139.566Z"
            fill="black"
          />
          <path
            d="M136.949 26.2198V70.6579H124.719V26.2198H114.469V15.1819H148.25L147.637 26.2198H136.954H136.949Z"
            fill="black"
          />
          <path
            d="M264.57 26.2198V70.6579H252.34V26.2198H242.09V15.1819H275.871L275.258 26.2198H264.575H264.57Z"
            fill="black"
          />
          <path
            d="M223.447 26.2198V70.6579H211.217V26.2198H200.967V15.1819H234.748L234.135 26.2198H223.452H223.447Z"
            fill="black"
          />
          <path
            d="M279.555 42.9247C279.555 39.4915 279.895 36.0096 280.571 32.4647C281.248 28.9246 282.459 25.7099 284.201 22.8254C285.942 19.936 288.316 17.5856 291.323 15.7597C294.329 13.9338 298.163 13.0258 302.823 13.0258C307.484 13.0258 311.317 13.9387 314.324 15.7597C317.33 17.5856 319.704 19.9408 321.446 22.8254C323.188 25.7099 324.394 28.9246 325.075 32.4647C325.751 36.0048 326.092 39.4866 326.092 42.9247C326.092 46.3531 325.751 49.8398 325.075 53.3799C324.399 56.92 323.188 60.1347 321.446 63.0192C319.704 65.9086 317.335 68.259 314.324 70.0849C311.317 71.9107 307.484 72.8237 302.823 72.8237C298.163 72.8237 294.329 71.9107 291.323 70.0849C288.316 68.259 285.942 65.9086 284.201 63.0192C282.459 60.1347 281.253 56.92 280.571 53.3799C279.895 49.8398 279.555 46.358 279.555 42.9247ZM292.826 42.9247C292.826 44.4495 292.899 46.2706 293.045 48.3975C293.191 50.5197 293.59 52.5641 294.237 54.5211C294.884 56.4829 295.876 58.1437 297.2 59.5034C298.528 60.868 300.401 61.5478 302.818 61.5478C305.178 61.5478 307.036 60.868 308.394 59.5034C309.751 58.1437 310.753 56.4829 311.4 54.5211C312.047 52.5592 312.446 50.5197 312.592 48.3975C312.738 46.2706 312.811 44.4495 312.811 42.9247C312.811 41.3999 312.738 39.574 312.592 37.4519C312.446 35.3298 312.047 33.2854 311.4 31.3235C310.748 29.3617 309.746 27.7057 308.394 26.3412C307.036 24.9815 305.178 24.2968 302.818 24.2968C300.401 24.2968 298.528 24.9766 297.2 26.3412C295.871 27.7009 294.884 29.3617 294.237 31.3235C293.59 33.2854 293.191 35.3249 293.045 37.4519C292.899 39.574 292.826 41.3999 292.826 42.9247Z"
            fill="black"
          />
          <path
            d="M174.472 70.5996C162.315 70.5996 154.74 61.7712 154.74 48.8249V15.245H166.474V47.7614C166.474 53.5499 168.265 58.877 174.535 58.877C180.869 58.877 182.562 53.5499 182.562 47.7614V15.245H194.394V48.8249C194.394 61.7712 186.556 70.5996 174.472 70.5996Z"
            fill="black"
          />
        </svg>
      )
      break

    default:
      return (
        <svg
          width="432"
          height="164"
          viewBox="0 0 432 164"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M407.715 116.046C409.081 116.046 411.812 116.744 412.69 120.134C414.543 128.11 397.864 135.288 397.864 135.288L400.79 139.076C418.737 130.303 431.905 117.143 432.002 99.9953C432.1 85.0409 422.346 73.0774 406.74 73.875C394.84 74.4731 385.574 84.3431 386.159 96.0075C386.647 106.974 395.913 116.046 407.715 116.046Z"
            fill="#0E0E0E"
          />
          <path
            d="M22.8259 25.9211C20.0948 26.0208 18.144 25.622 17.0711 22.4318C14.7302 14.9545 28.7758 6.28099 28.7758 6.28099L26.6299 3.4895C10.1458 13.0603 -1.07113 26.619 0.0993381 42.9691C1.07473 57.2257 11.0237 66.0986 22.8259 66.298C35.9937 66.4974 45.65 55.6305 44.0894 43.667C42.8214 33.1989 33.4577 25.5223 22.8259 25.9211Z"
            fill="#0E0E0E"
          />
          <path
            className="altogether-italian__small-stripe"
            d="M367.335 154.728H57.8438V164H367.335V154.728Z"
            fill="#0E0E0E"
          />
          <path
            d="M68.7678 59.3192L66.9145 63.9052H57.5508L75.3029 23.5283L92.9574 63.9052H83.4961L81.7404 59.3192H68.7678ZM75.3029 43.0687L71.6939 52.0414H78.8143L75.3029 43.0687Z"
            fill="#0E0E0E"
          />
          <path
            d="M118.904 63.9053H97.3477V24.8245H105.541V56.8269H119.391L118.904 63.9053Z"
            fill="#0E0E0E"
          />
          <path
            d="M135.876 32.6008V63.9053H127.488V32.6008H120.367V24.8245H143.581L143.191 32.6008H135.876Z"
            fill="#0E0E0E"
          />
          <path
            d="M164.551 64.304C153.529 64.304 145.043 55.1319 145.043 44.3648C145.043 33.5976 153.529 24.5253 164.551 24.5253C175.573 24.5253 184.059 33.6973 184.059 44.3648C184.059 55.0323 175.475 64.304 164.551 64.304ZM177.036 44.2651C177.036 37.3861 171.574 31.7034 164.746 31.7034C158.016 31.7034 152.456 37.3861 152.456 44.2651C152.456 51.1441 157.918 56.8268 164.746 56.8268C171.574 56.9265 177.036 51.2438 177.036 44.2651Z"
            fill="#0E0E0E"
          />
          <path
            d="M222.295 57.8238C218.979 62.0111 213.127 64.3041 207.957 64.3041C196.447 64.3041 188.449 54.5339 188.449 44.3649C188.449 32.3016 198.301 24.4257 207.957 24.4257C213.127 24.4257 218.979 26.7187 222.295 30.9059L217.321 36.0901C214.98 33.498 211.761 31.8032 208.055 31.8032C201.129 31.8032 195.765 37.2865 195.765 44.3649C195.765 51.4433 201.129 56.9266 208.055 56.9266C210.883 56.9266 213.322 56.0293 215.467 54.4342V44.4646H222.393L222.295 57.8238Z"
            fill="#0E0E0E"
          />
          <path
            d="M227.754 63.9053V24.8245H250.285V32.1023H235.947V43.0688H249.017L248.627 50.6457H235.947V56.8269H250.285V63.9053H227.754Z"
            fill="#0E0E0E"
          />
          <path
            d="M270.185 32.6008V63.9053H261.796V32.6008H254.773V24.8245H277.988L277.5 32.6008H270.185Z"
            fill="#0E0E0E"
          />
          <path
            d="M308.91 63.9054H300.619V50.8453H290.572V63.9054H282.379V24.8246H290.572V43.4677H300.619V24.8246H308.91V63.9054Z"
            fill="#0E0E0E"
          />
          <path
            d="M313.297 63.9054V24.8246H335.926V32.1024H321.588V43.069H334.658L334.268 50.6459H321.588V56.827H335.926V63.9054H313.297Z"
            fill="#0E0E0E"
          />
          <path
            d="M355.435 24.8246C360.897 24.8246 367.335 27.7158 367.335 37.2866C367.335 44.5644 363.628 48.5522 359.434 50.0477L367.042 63.8057H358.849L351.631 50.6459H348.607V63.8057H340.414V24.8246H355.435ZM348.607 43.4677H353.777C356.898 43.4677 359.337 40.8757 359.337 37.7851C359.337 34.5948 356.801 32.1024 353.777 32.1024H348.607V43.4677Z"
            fill="#0E0E0E"
          />
          <path
            d="M316.615 121.828C315.445 125.019 313.787 128.408 309.69 128.408C305.886 128.408 303.838 126.016 300.034 121.031C300.034 121.33 280.038 94.8108 280.038 94.8108C284.232 91.7202 288.622 89.0284 288.622 82.8472C288.622 76.2673 283.55 71.6813 274.576 71.6813C265.602 71.6813 256.629 77.0649 256.629 86.7354C256.629 93.5147 260.335 98.2004 263.457 102.288L282.964 128.01C287.549 133.692 292.231 138.976 300.229 138.976C309.397 138.976 316.42 130.003 318.566 122.427L316.615 121.828ZM271.26 80.3548C271.26 77.2642 273.406 74.7718 278.38 74.7718C283.159 74.7718 286.866 78.2612 286.866 82.8472C286.866 88.5299 282.964 90.8229 278.965 93.2156C274.088 86.7354 271.26 82.6478 271.26 80.3548Z"
            fill="#0E0E0E"
          />
          <path
            d="M302.373 108.469C302.958 108.469 304.031 108.768 304.324 110.064C305.104 113.255 298.374 116.246 298.374 116.246L299.544 117.741C306.86 114.152 312.127 108.868 312.224 101.889C312.224 95.8078 308.323 91.0224 301.983 91.3215C297.106 91.5209 293.399 95.5088 293.692 100.294C293.887 104.78 297.594 108.469 302.373 108.469Z"
            fill="#0E0E0E"
          />
          <path
            d="M259.849 116.146C258.288 116.245 257.117 115.946 256.532 114.152C255.167 109.765 263.36 104.78 263.36 104.78L262.092 103.185C252.533 108.768 245.998 116.644 246.583 126.115C247.168 134.39 252.923 139.574 259.751 139.674C267.457 139.774 273.016 133.493 272.138 126.514C271.456 120.333 265.994 115.847 259.849 116.146Z"
            fill="#0E0E0E"
          />
          <path
            d="M71.4015 73.8748H57.7461V139.076H71.4015V73.8748Z"
            fill="#0E0E0E"
          />
          <path
            d="M104.664 86.8352V139.076H90.6186V86.8352H78.8164V73.8748H117.637L116.954 86.8352H104.664Z"
            fill="#0E0E0E"
          />
          <path
            d="M134.606 131.499L131.583 139.076H115.781L145.336 71.4819L174.89 139.076H159.186L156.26 131.499H134.606ZM145.433 104.282L139.386 119.336H151.383L145.433 104.282Z"
            fill="#0E0E0E"
          />
          <path
            d="M218.394 139.076H182.402V73.8748H195.96V127.312H219.077L218.394 139.076Z"
            fill="#0E0E0E"
          />
          <path
            d="M240.241 73.8748H226.586V139.076H240.241V73.8748Z"
            fill="#0E0E0E"
          />
          <path
            d="M367.141 141.169L335.441 108.968V139.076H321.785V71.5815L353.388 105.578V73.7749H367.141V141.169Z"
            fill="#0E0E0E"
          />
        </svg>
      )
      break
  }
}
