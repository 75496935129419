import React from 'react'

const Ampersand = () => (
    <svg
        className="ampersand"
        width="59"
        height="55"
        viewBox="0 0 59 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.2746 7.44125C20.2746 4.98744 21.9923 3.02439 25.9184 3.02439C29.8445 3.02439 32.7891 5.72358 32.7891 9.4043C32.7891 13.8212 29.5991 15.7842 26.4091 17.7473C22.483 12.5943 20.2746 9.4043 20.2746 7.44125ZM57.0818 40.3223C56.1003 43.0215 54.8734 45.4753 51.438 45.4753C48.4935 45.4753 46.7758 43.5123 43.5858 39.5862C43.5858 39.8316 27.3907 18.7288 27.3907 18.7288C30.826 16.275 34.2613 14.0665 34.2613 9.15892C34.2613 4.00592 30.0899 0.325195 22.9738 0.325195C15.8578 0.325195 8.4963 4.49668 8.4963 12.1035C8.4963 17.5019 11.4409 21.1826 13.8947 24.3726L29.5991 44.7392C33.2798 49.1561 36.9605 53.3276 43.5858 53.3276C50.9473 53.3276 56.591 46.2115 58.3087 40.3223H57.0818Z"
            fill="currentColor"
        />
        <path
            d="M45.5489 29.7709C46.0397 29.7709 47.0212 30.0163 47.2665 30.9979C48.0027 33.4517 42.3589 35.9055 42.3589 35.9055L43.3405 37.1324C49.2296 34.1878 53.6465 30.0163 53.6465 24.6179C53.6465 19.7103 50.4565 16.0296 45.3035 16.275C41.3774 16.5204 38.4328 19.7103 38.6782 23.391C38.6782 26.8264 41.6228 29.7709 45.5489 29.7709Z"
            fill="currentColor"
        />
        <path
            d="M10.9501 35.9055C9.7232 35.9055 8.74171 35.6601 8.25095 34.1878C7.02404 30.7525 13.6493 26.8264 13.6493 26.8264L12.6678 25.5995C4.81561 30.0163 -0.337416 36.1509 0.153346 43.7577C0.644109 50.383 5.30634 54.3091 10.9501 54.5545C17.0846 54.5545 21.7469 49.6468 21.0108 44.2484C20.52 39.0954 16.1031 35.6601 10.9501 35.9055Z"
            fill="currentColor"
        />
    </svg>
)

export default Ampersand
