import React, { useContext, useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { RichText } from '@superrb/gatsby-addons/components'
import { PrismicLink, translations } from 'crostamollica-components/utils'
import { Image } from '@superrb/gatsby-addons/components'
import { linkResolver } from '../utils/linkResolver'
import { ImageLayout } from '@superrb/gatsby-addons/types'
import { LanguageContext } from 'crostamollica-components/context'

const WhereToBuy = ({ data }) => {
  const { language } = useContext(LanguageContext)
  const messages = translations(language)

  const [images, setImages] = useState(data?.images)

  const document = useStaticQuery(graphql`
    query GetInTouchQuery {
      contactPage: allPrismicContactPage {
        nodes {
          uid
          url
          type
          lang
        }
      }
    }
  `)

  let contactPage
  let i;
  for (i in document.contactPage.nodes) {
    if (document.contactPage.nodes[i].lang === language) {
      contactPage = document.contactPage.nodes[i]
    }
  }

  const sourceImages = data?.images
  useEffect(() => {
    setImages(() => {
      if (sourceImages.length >= 4 && sourceImages.length < 8) {
        return [...sourceImages, ...sourceImages]
      }

      return sourceImages
    })
  }, [sourceImages])

  if (images.length === 0) {
    return null
  }

  return (
    <section className="where-to-buy-home">
      <div className="where-to-buy-home__inner">
        <div className="where-to-buy-home__inner-text">
          {data.title && <h2>{data.title}</h2>}
          {data.content && <RichText field={data.content.richText} />}
          <div className="where-to-buy-home__link-container">
            {contactPage &&
              <Link
                to={linkResolver(contactPage)}
                className="where-to-buy-home__link"
              >
                <span className="instagram-feed__button forward insta-forward"></span>
                {messages.homepage.contact_cta.button_label}
              </Link>
            }
          </div>
          <div
            className={`where-to-buy-home__stockists  ${
              images.length >= 4 ? 'where-to-buy-home__stockists--loop' : ''
            }`}
          >
            <div className="where-to-buy-home__stockists-list">
              {images.map((stockist, index) => {
                return (
                  <div
                    key={index}
                    className="where-to-buy-home__stockists-list-single"
                  >
                    <Image
                      className="image"
                      image={stockist.stockist_image}
                      loading="eager"
                      layout={ImageLayout.contain}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhereToBuy
