class ContactFormResource {
  constructor(data, language) {
    this.data = new FormData()

    data.files.forEach(file => {
      this.data.append('files', file.data)
    })
    delete data.files

    this.data.append('language', language ? language.toUpperCase() : 'EN')

    for (let key in data) {
      this.data.append(key, data[key])
    }
  }

  payload() {
    return {
      method: 'POST',
      body: this.data,
    }
  }

  post() {
    return new Promise((resolve, reject) => {
      const url = process.env.GATSBY_WORKER_CONTACT_HANDLER

      const payload = this.payload()

      fetch(url, payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default ContactFormResource
