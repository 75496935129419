import React, { useContext } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { RichText } from '@superrb/gatsby-addons/components'
import { PrismicLink, translations } from 'crostamollica-components/utils'
import { linkResolver } from '../utils/linkResolver'
import { LanguageContext } from 'crostamollica-components/context'

const GetInTouch = ({ ...data }) => {
  const { language } = useContext(LanguageContext)
  const messages = translations(language)

  const document = useStaticQuery(graphql`
    query GetInTouchQuery {
      contactPage: allPrismicContactPage {
        nodes {
          uid
          url
          type
          lang
        }
      }
    }
  `)

  let contactPage
  let i;
  for (i in document.contactPage.nodes) {
    if (document.contactPage.nodes[i].lang === language) {
      contactPage = document.contactPage.nodes[i]
    }
  }

  return (
    <section className="get-in-touch">
      <div className="get-in-touch__inner">
        <hr />
        <div className="get-in-touch__inner-text">
          {data.data.title && <h2>{data.data.title}</h2>}
          {data.data.content && <RichText field={data.data.content.richText} />}
          <div className="get-in-touch__link-container">
            {contactPage &&
              <Link to={linkResolver(contactPage)} className="get-in-touch__link">
                <span className="instagram-feed__button forward insta-forward"></span>
                {messages.homepage.contact_cta.button_label}
              </Link>
            }
          </div>
        </div>
        <hr />
      </div>
    </section>
  )
}

export default GetInTouch
