import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Logo from './logo'
import HeaderNavigation from './header-navigation'
import MenuToggle from './nav/menu-toggle'
import { NavOpenContext } from './context/nav-open-context'
import {useContext} from "react";
import { LanguageContext } from "crostamollica-components/context";

const Header = ({ siteTitle }) => {
  const { language } = useContext(LanguageContext)
  let link = `/`

  switch (language) {
    case 'fr-be':
    case 'nl-be':
      link = `/${language}`
      break

    default:
      link = `/`
      break
  }

  return (
    <NavOpenContext.Consumer>
      {({navOpen, toggleNav}) => (
        <header className="header">
          <div className="header__container container container--flex">
            <Link
              to={link}
              className={[
                'header__logo',
                navOpen['product-subnav'] ? 'header__logo--hide-stripes' : '',
              ].join(' ')}
            >
              <Logo/>
              <span className="screenreader-text">{siteTitle}</span>
            </Link>
            {/* <MenuToggle
              target="main-nav"
              className="header__menu-toggle"
              activeClassName="header__menu-toggle--active"
              label={<span className="screenreader-text">Navigation</span>}
            /> */}
            {/* <HeaderNavigation /> */}
          </div>
        </header>
      )}
    </NavOpenContext.Consumer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
