import React, { Component } from 'react'
import { RequiredMessage } from './required-message'

export class ContactTextarea extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: false,
    }
  }

  setFocus = () => {
    this.inputRef.focus()
  }

  onBlur = () => {
    if (this.props.value === '') {
      this.setState({
        selected: false,
      })
    }
  }

  setSelected = () => {
    this.setState({
      selected: true,
    })
  }

  render() {
    const wrapperClass = `contact-form__field ${
      this.props.failed ? 'failed' : ''
    } ${this.state.selected ? 'selected' : ''}`

    return (
      <>
        <div className={wrapperClass} onClick={this.setFocus}>
          <label className="contact-form__label" htmlFor={this.props.id}>
            {this.props.label}
          </label>
          <textarea
            className="contact-form__textarea"
            id={this.props.id}
            rows="5"
            onChange={event => this.props.onChange(event.currentTarget.value)}
            defaultValue={this.props.value}
            ref={ref => (this.inputRef = ref)}
            onFocus={this.setSelected}
            onBlur={this.onBlur}
          ></textarea>
        </div>

        {this.props.failed && <RequiredMessage />}
      </>
    )
  }
}
