import React from 'react'
import { Stripes } from 'crostamollica-components/assets'
import { LogoImage } from 'crostamollica-components/assets'

const Logo = () => (
  <div className="logo">
    <LogoImage />
    <Stripes />
  </div>
)

export default Logo
