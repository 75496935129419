import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FaqItem } from 'crostamollica-components/components'

const Faqs = () => {
  const data = useStaticQuery(graphql`
    query FaqsQuery {
      allPrismicFaq(sort: { data: { display_order: ASC } }) {
        nodes {
          uid
          data {
            question {
              html
              richText
              text
            }
            display_order
            answer {
              html
              richText
              text
            }
          }
        }
      }
    }
  `)

  return (
    <div className="contact-faq__questions__container">
      <section className="contact-faq__questions__questions">
        {data.allPrismicFaq.nodes.map((faq, index) => (
          <FaqItem faq={faq} key={index} />
        ))}
      </section>
    </div>
  )
}

export default Faqs
