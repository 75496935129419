import React, {
  Component,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import 'animate.css'
import '../photo-slider/photo-gallery-animate-override.css'
import { RichText } from '@superrb/gatsby-addons/components'
import { BigArrowLeft } from './big-arrow-left'
import { BigArrowRight } from './big-arrow-right'
import Heading from '../content/heading'
import NewTag from '../icons/new-tag'
import { translations } from 'crostamollica-components/utils'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import { LanguageContext } from 'crostamollica-components/context'

export const FeaturedProductsHomeAlt = ({ products, title, id }) => {
  const { language } = useContext(LanguageContext)
  const messages = translations(language)

  const [productStack, setProductStack] = useState(products[0])
  const [index, setIndex] = useState(0)
  const [incoming, setIncoming] = useState(false)
  const [triggerIncoming, setTriggerIncoming] = useState(true)
  const [cache, setCache] = useState([])
  const [rootMinHeight, setRootMinHeight] = useState(null)

  const rootRef = useRef(null)
  const { isInViewport, setRef } = useIsInViewport(false)

  useEffect(() => {
    setProductStack(stack => [products[0]])
  }, [products, setProductStack])

  const measureHeight = useCallback(() => {
    if (!rootRef.current) {
      return
    }

    const clientHeight = rootRef.current.clientHeight
    const height = rootMinHeight || 0

    if (clientHeight > height) {
      setRootMinHeight(clientHeight)
    }
  }, [setRootMinHeight])

  const preloadCache = useCallback(() => {
    setCache(products)
  }, [setCache])

  useEffect(() => {
    if (triggerIncoming === true) {
      setIncoming(true)
      setTriggerIncoming(false)
    }

    measureHeight()
  }, [triggerIncoming, measureHeight])

  useEffect(() => {
    if (isInViewport) {
      preloadCache()
    }
  }, [isInViewport])

  const page = useCallback(
    value => {
      let newIndex = index + value

      if (newIndex >= products.length) {
        newIndex = 0
      }

      if (newIndex < 0) {
        newIndex = products.length - 1
      }

      setIndex(newIndex)
      setIncoming(false)
      setTriggerIncoming(true)
      setProductStack([products[index]])
    },
    [
      index,
      products,
      setIndex,
      setIncoming,
      setTriggerIncoming,
      setProductStack,
    ],
  )

  const back = useCallback(() => {
    page(-1)
  }, [page])

  const next = useCallback(() => {
    page(1)
  }, [page])

  const padZero = useCallback(value => {
    let result = value

    try {
      result = value.toString().padStart(2, '0')
    } catch (e) {
      // pass
    }

    return result
  }, [])

  const calculatePageClass = useCallback(
    (i, length) =>
      `featured-products-home__page-number ${i === index ? 'active' : ''} ${
        i < length ? 'dashed' : ''
      }`,
    [index],
  )

  if (products.length === undefined) {
    return <div />
  }

  const group = products[index]

  const rootStyle = {
    minHeight: rootMinHeight,
  }

  return (
    <section
      className="featured-products-home featured-products-home__holding alternative featured-products-alt"
      id={`productslider_id_${id}`}
      ref={rootRef}
      style={rootStyle}
      aria-live="assertive"
    >
      <div className="feature-products-home__container" ref={setRef}>
        <div className="featured-products-home__info-panel">
          <div className="featured-products-home__header">
            <h5 className="featured-products-home__sub-heading-before">
              {messages.homepage.featured_products.title}
            </h5>
            <Heading
              level="3"
              className="featured-products-home__sub-heading"
              text={title}
            />
            <div className="featured-products-home__controls">
              <span className="featured-products-home__pages">
                {products.map((product, index) => (
                  <span
                    className={calculatePageClass(index, products.length)}
                    key={index}
                  >
                    {padZero(index + 1)}
                  </span>
                ))}
              </span>
              <span className="featured-products-home__buttons">
                <button
                  className="featured-products-home__control-button"
                  onClick={back}
                >
                  <BigArrowLeft />
                  <span>Previous product</span>
                </button>
                <button
                  className="featured-products-home__control-button"
                  onClick={next}
                >
                  <BigArrowRight />
                  <span>Next product</span>
                </button>
              </span>
            </div>
          </div>
          {incoming && group ? (
            <>
              <div
                className={`animate__animated animate__fadeInUp animate__faster animate__delay-250ms`}
              >
                <div className="six-column-block__column featured-products-home__right">
                  {group.map((product, index) => (
                    <div
                      className="featured-products-home__card-top"
                      key={index}
                    >
                      {product.new_product && (
                        <span className="featured-products-home__card-new">
                          <NewTag />
                        </span>
                      )}
                      <div className="featured-products-home__card">
                        <Image
                          image={product.background_image}
                          sizes="(min-width: 45em) 25vw, 50vw"
                          className="featured-products-home__card-background"
                        />
                        <Image
                          image={product.product_image}
                          sizes="(min-width: 45em) 25vw, 50vw"
                          className="featured-products-home__card-foreground"
                        />
                        <div className="featured-products-home__card-text">
                          <h4>{product.product_title}</h4>
                          <RichText
                            field={product.product_description.richText}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr />
            </>
          ) : null}
        </div>
      </div>
      <div className="featured-products-home__prewarm" aria-hidden="true"></div>
    </section>
  )
}
