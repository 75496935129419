import React from 'react'

export const AboutInfo = () => {
  return (
    <div className="contact-form__about">
      <div
        // dangerouslySetInnerHTML={{ __html: messages.contact_form.recaptcha }}
      />
    </div>
  )
}
