import React, { Component } from 'react'
import { FaqIndicator } from 'crostamollica-components/components'
import { RequiredMessage } from './required-message'

export class ContactSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: false,
      completed: false,
      choice: 0,
    }
  }

  setFocus = () => {
    this.inputRef.focus()
  }

  onBlur = () => {
    if (this.props.value === '') {
      this.setState({
        selected: false,
      })
    }
  }

  setSelected = () => {
    this.setState({
      selected: true,
    })
  }

  selectItem = event => {
    const value = event.currentTarget.value
    const option = event.target.options[event.target.selectedIndex].dataset.choice

    const newState = {}

    newState.selected = false
    newState.completed = value !== ''

    this.setState(newState, () => {
      this.props.onChange(value, option)
    })
  }

  openSelect = () => {
    this.inputRef.focus()
    if (this.inputRef !== undefined) {
      if (document.createEvent) {
        this.inputRef.dispatchEvent(new MouseEvent('mousedown'))
      }
    }
  }

  render() {
    const wrapperClass = `contact-form__field ${
      this.props.failed ? 'failed' : ''
    } ${this.state.selected ? 'selected' : ''} ${
      this.state.completed ? 'completed' : ''
    }`

    return (
      <>
        <div className={wrapperClass} onClick={this.setFocus}>
          <label className="contact-form__label" htmlFor={this.props.id}>
            {this.props.label}
          </label>
          <input
            autoComplete="off"
            className="contact-form__input"
            type="text"
            name={this.props.id + '_'}
            id={this.props.id + '_'}
            required={true}
            defaultValue={this.props.value}
          />
          <select
            className="contact-form__select"
            id={this.props.id}
            value={this.props.value}
            onChange={this.selectItem}
            onFocus={this.setSelected}
            onBlur={this.onBlur}
            ref={ref => (this.inputRef = ref)}
          >
            <option value="" key={true}>
              Please select...
            </option>
            {this.props.options.map((subject, index) => (
              <option value={subject} key={subject} data-choice={index}>
                {subject}
              </option>
            ))}
          </select>
          <div className="contact-form__select-indicator">
            <FaqIndicator open={false} />
          </div>
        </div>

        {this.props.failed && <RequiredMessage />}
      </>
    )
  }
}
