import React from 'react'

export const BigArrowLeft = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="28.5"
        cy="28.5"
        r="27.5"
        transform="rotate(-180 28.5 28.5)"
        stroke="#0E0E0E"
        strokeWidth="2"
      />
      <path
        d="M35 28L21 28"
        stroke="#0E0E0E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 35L21 28L28 21"
        stroke="#0E0E0E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
