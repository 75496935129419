import React from 'react'

const NewTag = () => {
  switch (process.env.GATSBY_LANGUAGE) {
    case 'fr':
    case 'fr-fr':
    case 'fr-be':
      return (
        <svg
          width="81"
          height="81"
          viewBox="0 0 81 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.3802 80.7604C62.6816 80.7604 80.7604 62.6816 80.7604 40.3802C80.7604 18.0788 62.6816 0 40.3802 0C18.0788 0 0 18.0788 0 40.3802C0 62.6816 18.0788 80.7604 40.3802 80.7604Z"
            fill="#FFCD00"
          />
          <path
            d="M40.4553 5.53644C59.7507 5.53644 75.3636 21.1493 75.3636 40.4448C75.3636 59.7403 59.7507 75.3532 40.4553 75.3532C21.1598 75.3532 5.54687 59.7403 5.54687 40.4448C5.54687 21.1493 21.1598 5.53644 40.4553 5.53644Z"
            stroke="white"
            stroke-width="3"
          />
          <path
            d="M12.9162 36.1641H11V44.8359H13.1278L13.0808 39.6094L16.4077 44.8359H18.3357V36.1641H16.2079L16.2549 41.4492L12.9162 36.1641Z"
            fill="#0E0E0E"
          />
          <path
            d="M19.7249 40.6055C19.7249 42.832 21.5588 45 24.2979 45C27.0018 45 28.9063 42.9141 28.9063 40.5C28.9063 38.0859 26.99 36 24.3097 36C21.9115 36 19.7249 37.7578 19.7249 40.5938V40.6055ZM21.9468 40.4766C21.9468 39.2578 22.7814 37.9453 24.3215 37.9453C25.9203 37.9453 26.6844 39.3867 26.6844 40.5117C26.6844 41.625 25.9203 43.0547 24.345 43.0547C22.852 43.0547 21.9468 41.8125 21.9468 40.4883V40.4766Z"
            fill="#0E0E0E"
          />
          <path
            d="M30.2578 36.1641V41.4023C30.2578 42.6797 30.4107 43.2891 31.1278 44.0742C31.8449 44.8477 32.997 45 33.6318 45C34.7133 45 35.501 44.6367 36.0652 44.0625C36.6178 43.5 36.9352 42.8555 36.9352 41.4023V36.1641H34.7133V41.4023C34.7133 41.7422 34.7133 42.1641 34.5722 42.4922C34.4429 42.8086 34.1608 43.0547 33.6083 43.0547C33.0675 43.0547 32.7854 42.7852 32.6325 42.4453C32.4915 42.1055 32.4797 41.6953 32.4797 41.4023V36.1641H30.2578Z"
            fill="#0E0E0E"
          />
          <path
            d="M40.9748 44.8359H43.2672L46.1827 36.1641H43.8903L42.1151 42.2461L40.3518 36.1641H38.0711L40.9748 44.8359Z"
            fill="#0E0E0E"
          />
          <path
            d="M47.3355 44.8359H52.2612V42.8906H49.5574V41.4023H52.1789V39.457H49.5574V38.1094H52.2612V36.1641H47.3355V44.8359Z"
            fill="#0E0E0E"
          />
          <path
            d="M55.8551 43.582H58.8998L59.3113 44.8359H61.7448L58.4061 36.1641H56.4899L53.0454 44.8359H55.4554L55.8551 43.582ZM58.3238 41.8242H56.4076L57.3481 38.8008L58.3238 41.8242Z"
            fill="#0E0E0E"
          />
          <path
            d="M62.3227 36.1641V41.4023C62.3227 42.6797 62.4755 43.2891 63.1926 44.0742C63.9097 44.8477 65.0618 45 65.6966 45C66.7781 45 67.5658 44.6367 68.1301 44.0625C68.6826 43.5 69 42.8555 69 41.4023V36.1641H66.7781V41.4023C66.7781 41.7422 66.7781 42.1641 66.6371 42.4922C66.5078 42.8086 66.2256 43.0547 65.6731 43.0547C65.1323 43.0547 64.8502 42.7852 64.6973 42.4453C64.5563 42.1055 64.5445 41.6953 64.5445 41.4023V36.1641H62.3227Z"
            fill="#0E0E0E"
          />
        </svg>
      )
      break

    case 'de':
    case 'de-de':
      return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M40.3802 80.7604C62.6816 80.7604 80.7604 62.6816 80.7604 40.3802C80.7604 18.0788 62.6816 0 40.3802 0C18.0788 0 0 18.0788 0 40.3802C0 62.6816 18.0788 80.7604 40.3802 80.7604Z" fill="#FFCD00"/>
          <path d="M40.4553 5.53644C59.7507 5.53644 75.3636 21.1493 75.3636 40.4448C75.3636 59.7403 59.7507 75.3532 40.4553 75.3532C21.1598 75.3532 5.54687 59.7403 5.54687 40.4448C5.54687 21.1493 21.1598 5.53644 40.4553 5.53644Z" stroke="white" stroke-width="3"/>
          <path d="M49.5625 34H52.5865V41.152C52.5865 41.552 52.6025 42.112 52.7945 42.576C53.0025 43.04 53.3865 43.408 54.1225 43.408C54.8745 43.408 55.2585 43.072 55.4345 42.64C55.6265 42.192 55.6265 41.616 55.6265 41.152V34H58.6505V41.152C58.6505 43.136 58.2185 44.016 57.4665 44.784C56.6985 45.568 55.6265 46.064 54.1545 46.064C53.2905 46.064 51.7225 45.856 50.7465 44.8C49.7705 43.728 49.5625 42.896 49.5625 41.152V34Z" fill="#0E0E0E"/>
          <path d="M38.1406 45.84V34H44.8446V36.656H41.1646V38.496H44.7326V41.152H41.1646V43.184H44.8446V45.84H38.1406Z" fill="#0E0E0E"/>
          <path d="M25.608 34L30.152 41.216L30.088 34H32.984V45.84H30.36L25.832 38.704L25.896 45.84H23V34H25.608Z" fill="#0E0E0E"/>
        </svg>
      )
      break

    default:
      return (
        <svg
          width="81"
          height="81"
          viewBox="0 0 81 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40.3802 80.7604C62.6816 80.7604 80.7604 62.6816 80.7604 40.3802C80.7604 18.0788 62.6816 0 40.3802 0C18.0788 0 0 18.0788 0 40.3802C0 62.6816 18.0788 80.7604 40.3802 80.7604Z"
            fill="#FFCD00"
          />
          <path
            d="M40.4544 5.5365C59.7499 5.5365 75.3628 21.1494 75.3628 40.4449C75.3628 59.7404 59.7499 75.3533 40.4544 75.3533C21.1589 75.3533 5.54602 59.7404 5.54602 40.4449C5.54602 21.1494 21.1589 5.5365 40.4544 5.5365Z"
            stroke="white"
            stroke-width="3"
          />
          <path
            d="M24.3159 34.5208H21.7336V46.244H24.6011L24.5377 39.1784L29.0211 46.244H31.6192V34.5208H28.7517L28.8151 41.6656L24.3159 34.5208Z"
            fill="#0E0E0E"
          />
          <path
            d="M35.4444 46.244H42.0823V43.6142H38.4385V41.6022H41.9714V38.9724H38.4385V37.1506H42.0823V34.5208H35.4444V46.244Z"
            fill="#0E0E0E"
          />
          <path
            d="M47.7219 46.244H50.4309L52.1894 38.6556L53.9795 46.244H56.6094L59.7144 34.5208H56.6886L55.0885 42.1725L53.3617 34.5208H51.0646L49.3378 42.1409L47.7377 34.5208H44.696L47.7219 46.244Z"
            fill="#0E0E0E"
          />
        </svg>
      )
      break
  }
}

export default NewTag
