/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import {
  HreflangContextProvider,
  LanguageContextProvider,
} from 'crostamollica-components/context'
import { repositoryConfigs } from './src/utils/prismic-previews'

import React, { StrictMode } from 'react'
import {
  navOpen,
  Provider as NavOpenContextProvider,
  closeNav,
} from './src/components/context/nav-open-context'

import Layout from './src/components/layout'
import { ContextWrapper } from '@superrb/gatsby-addons/components'

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

export const wrapRootElement = ({ element }) => (
  <StrictMode>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <LanguageContextProvider>
        <HreflangContextProvider>
          <ContextWrapper>
            <NavOpenContextProvider>{element}</NavOpenContextProvider>
          </ContextWrapper>
        </HreflangContextProvider>
      </LanguageContextProvider>
    </PrismicPreviewProvider>
  </StrictMode>
)

export const onRouteUpdate = ({ location: { pathname } }) => {
  if ('navOpenProvider' in window) {
    Object.keys(navOpen).forEach(key =>
      closeNav.call(window.navOpenProvider, key),
    )
  }

  if (pathname === '/') {
    pathname = 'home'
  }

  document.body.setAttribute(
    'class',
    `page page--intl ${[
      ...pathname
        .split('/')
        .filter(x => !!x)
        .map(slug => `page--${slug}`),
    ].join(' ')}`,
  )
}
