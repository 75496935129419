import React, {useContext} from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import {LanguageContext} from "crostamollica-components/src/context/language-context";

const LegalPageNavigation = () => {
  const { language } = useContext(LanguageContext)

  const data = useStaticQuery(graphql`
    query LegalPageNavigationQuery {
      allPrismicLegalPage(sort: { last_publication_date: ASC }) {
        nodes {
          data {
            meta_navigation_title
          }
          uid
          lang
          url
          type
        }
      }
    }
  `)

  let legalPages = []
  let i;
  for (i in data.allPrismicLegalPage.nodes) {
    if (data.allPrismicLegalPage.nodes[i].lang === language) {
      legalPages.push(data.allPrismicLegalPage.nodes[i])
    }
  }

  return (
    <>
      {legalPages.map((legalPage, index) => {
        const url = linkResolver(legalPage)

        return (
          <li className="nav__list-item" key={index}>
            <Link
              to={url}
              className="nav__link"
              activeClassName="nav__link--active"
            >
              {legalPage.data.meta_navigation_title}
            </Link>
          </li>
        )
      })}
    </>
  )
}

export default LegalPageNavigation
