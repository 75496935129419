import React, { useCallback, useContext, useEffect, useState } from 'react'
import { translations } from 'crostamollica-components/utils'
import { LanguageContext } from 'crostamollica-components/context'
import {RequiredMessage} from "./required-message";
// import convert from "heic-convert"

const ALLOWED_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/heic',
  'image/heic-sequence',
  'application/pdf',
]

const FilePreview = ({ file }) => {
  switch (true) {
    case file.image.startsWith('data:application/pdf;base64,'):
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <path d="M78.9,24.3L60.4,5.5H18.9v89h60v-6.8h11V52.6h-11V24.3z M58.4,7.7l18.5,18.6H58.4V7.7z M86.9,55.6v29.1H30.4V55.6H86.9z" />
          <path d="M42.5,72.9c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.3,0c0.7,0,1.4-0.1,2.1-0.3    c0.7-0.2,1.3-0.5,1.8-0.9c0.5-0.4,1-0.9,1.3-1.6s0.5-1.4,0.5-2.3c0-1-0.2-1.9-0.6-2.5c-0.4-0.6-0.9-1.1-1.4-1.5    c-0.6-0.4-1.2-0.6-2-0.7c-0.7-0.1-1.5-0.2-2.2-0.2c-0.7,0-1.4,0-2.1,0.1c-0.7,0.1-1.4,0.2-1.9,0.3v14.6h2.9V72.9z M42.5,65.6    c0.2,0,0.4-0.1,0.6-0.1s0.5,0,0.8,0c0.4,0,0.7,0,1.1,0.1c0.3,0.1,0.7,0.2,0.9,0.4c0.3,0.2,0.5,0.4,0.7,0.7    c0.2,0.3,0.3,0.7,0.3,1.1c0,0.5-0.1,1-0.3,1.3c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.6,0.3-1,0.4c-0.4,0.1-0.7,0.1-1.1,0.1    c-0.1,0-0.1,0-0.3,0c-0.1,0-0.2,0-0.4,0s-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0V65.6z" />
          <path d="M55.6,78.1c0.4,0,0.7,0,1.1,0.1c0.3,0,0.6,0,0.9,0c1.4,0,2.6-0.2,3.5-0.7c0.9-0.4,1.6-1,2.1-1.7s0.9-1.5,1.1-2.5    c0.2-0.9,0.3-1.9,0.3-2.8c0-1.1-0.1-2-0.3-3c-0.2-0.9-0.5-1.7-1-2.4s-1.2-1.2-2-1.6c-0.9-0.4-1.9-0.6-3.2-0.6c-0.3,0-0.7,0-1.1,0    c-0.4,0-0.8,0-1.2,0.1c-0.4,0-0.8,0-1.1,0.1c-0.3,0-0.5,0-0.7,0.1V78c0.2,0,0.4,0.1,0.8,0.1S55.3,78.1,55.6,78.1z M56.7,65.7    c0.1,0,0.3,0,0.6-0.1s0.5,0,0.8,0c0.8,0,1.4,0.2,1.9,0.5c0.5,0.3,0.8,0.7,1,1.2c0.2,0.5,0.4,1,0.4,1.6s0.1,1.1,0.1,1.7    c0,0.6,0,1.2-0.1,1.8c-0.1,0.6-0.2,1.2-0.5,1.7c-0.2,0.5-0.6,0.9-1.1,1.2c-0.5,0.3-1.1,0.5-1.9,0.5c-0.1,0-0.2,0-0.3,0    c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0V65.7z" />
          <polygon points="72.1,72 78.1,72 78.1,69.4 72.1,69.4 72.1,65.8 78.5,65.8 78.5,63.2 69.2,63.2 69.2,77.9 72.1,77.9   " />
        </svg>
      )

    case file.image.startsWith('data:image/heic;base64,'):
    case file.image.startsWith('data:image/heic-sequence;base64,'):
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <path d="M78.9,24.3L60.4,5.5H18.9v89h60v-6.8h11V52.6h-11V24.3z M58.4,7.7l18.5,18.6H58.4V7.7z M86.9,55.6v29.1H30.4V55.6H86.9z" />
          <path d="M44.9,74.8c-0.3,0.3-0.6,0.5-0.9,0.6s-0.7,0.2-1,0.2c-0.5,0-0.9,0-1.3-0.1c-0.4-0.1-0.8-0.3-1.4-0.6l-1,2.3    c0.6,0.4,1.3,0.6,1.9,0.8c0.6,0.1,1.3,0.2,2,0.2c0.2,0,0.5,0,0.8,0s0.7-0.1,1-0.2c0.4-0.1,0.7-0.2,1.1-0.4    c0.4-0.2,0.7-0.5,1.1-0.8c0.5-0.5,0.9-1.2,1.1-1.9s0.3-1.4,0.3-2.2v-9.4h-8.8v2.6h5.9v6.8C45.6,73.6,45.4,74.3,44.9,74.8z" />
          <path d="M56.9,72.9c0.1,0,0.2,0,0.3,0s0.3,0,0.5,0s0.3,0,0.5,0c0.2,0,0.3,0,0.3,0c0.7,0,1.4-0.1,2.1-0.3c0.7-0.2,1.3-0.5,1.8-0.9    c0.5-0.4,1-0.9,1.3-1.6c0.3-0.6,0.5-1.4,0.5-2.3c0-1-0.2-1.9-0.6-2.5c-0.4-0.6-0.9-1.1-1.4-1.5c-0.6-0.4-1.2-0.6-2-0.7    c-0.7-0.1-1.5-0.2-2.2-0.2c-0.7,0-1.4,0-2.1,0.1c-0.7,0.1-1.4,0.2-1.9,0.3v14.6h2.9V72.9z M56.9,65.6c0.2,0,0.4-0.1,0.6-0.1    c0.3,0,0.5,0,0.8,0c0.4,0,0.7,0,1.1,0.1c0.3,0.1,0.7,0.2,0.9,0.4c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.3,0.7,0.3,1.1    c0,0.5-0.1,1-0.3,1.3c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.6,0.3-1,0.4c-0.4,0.1-0.7,0.1-1.1,0.1c-0.1,0-0.1,0-0.3,0    c-0.1,0-0.2,0-0.4,0s-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0V65.6z" />
          <path d="M70,76.3c0.6,0.7,1.3,1.1,2.1,1.5c0.8,0.3,1.7,0.5,2.6,0.5c0.8,0,1.6-0.1,2.3-0.3s1.4-0.6,1.9-1v-6.6h-5v2.1h2.7v2.9    c-0.1,0.1-0.3,0.2-0.6,0.3c-0.3,0.1-0.6,0.1-1,0.1c-0.6,0-1.1-0.1-1.6-0.3s-0.9-0.6-1.2-1c-0.4-0.4-0.6-1-0.8-1.6    c-0.2-0.6-0.3-1.3-0.3-2.1c0-0.9,0.1-1.7,0.3-2.3c0.2-0.6,0.5-1.2,0.8-1.6c0.4-0.4,0.8-0.7,1.3-0.9s1-0.3,1.6-0.3    c0.3,0,0.6,0,0.9,0.1c0.1,0,0.2,0.1,0.3,0.1V68h2.3v-4.3c-0.5-0.2-1-0.4-1.5-0.5c-0.6-0.1-1.3-0.2-2.1-0.2c-0.8,0-1.6,0.1-2.5,0.4    c-0.8,0.3-1.6,0.7-2.2,1.3c-0.7,0.6-1.2,1.4-1.6,2.3c-0.4,1-0.6,2.1-0.6,3.5c0,1.3,0.2,2.4,0.5,3.4C68.9,74.8,69.4,75.6,70,76.3z" />
        </svg>
      )

    case file.image.startsWith('data:image/jpg;base64,'):
    case file.image.startsWith('data:image/png;base64,'):
    case file.image.startsWith('data:image/jpeg;base64,'):
      return <img src={file.image} alt={file.name} />

    default:
      throw new Error(`Unsupported file type: ${file.image}`)
  }
}

const SelectedFile = ({ file, index, onRemove }) => {
  return (
    <li className="file-input__file">
      <div className="file-input__file-image">
        <div className="file-input__file-image-preview">
          <div className="file-input__file-image-wrapper">
            <FilePreview file={file} />
          </div>
        </div>
      </div>
      <span className="file-input__file-name">{file.name}</span>

      <button
        type="button"
        data-index={index}
        className="file-input__file-remove"
        onClick={onRemove}
      >
        <span className="screenreader-text">Remove file</span>
      </button>
    </li>
  )
}

const ContactFileInput = ({
  failed,
  id,
  label,
  buttonLabel,
  onChange,
  value = [],
  max = null,
  required
}) => {
  const { language } = useContext(LanguageContext)
  const messages = translations(language)

  const [files, setFiles] = useState(value)
  const [error, setError] = useState(null)

  const addFile = useCallback(
    file => {
      setFiles(files => {
        const newFiles = [...files, file]

        if (max && newFiles.length > max) {
          newFiles.splice(max)
          setError(`You can only upload ${max} files`)
        } else {
          setError(null)
        }

        return newFiles
      })
    },
    [max, setFiles],
  )

  useEffect(() => {
    onChange(files)
  }, [files])

  const handleClick = useCallback(
    async event => {
      setError(null)
    },
    [setError],
  )

  const handleChange = useCallback(
    async event => {
      setError(null)
      if (event.target.files) {
        ;[...event.target.files].forEach(async file => {
          if (!ALLOWED_TYPES.includes(file.type)) {
            setError(messages.contact_form.errors.unsupported_file_type)

            return
          }

          const fileData = {
            name: file.name,
            data: file,
            image: null,
          }

          await new Promise(resolve => {
            const fileReader = new FileReader()
            fileReader.onload = e => {
              fileData.image = e.target.result
              resolve()
            }
            fileReader.readAsDataURL(file)
          })

          addFile(fileData)
        })
      }
    },
    [addFile, setError],
  )

  const removeFile = useCallback(
    event => {
      const button = event.currentTarget || event.target
      const index = parseInt(button.dataset.index, 10)
      setFiles(files => files.filter((_, i) => i !== index))
      setError(null)
    },
    [setFiles],
  )

  return (
    <div className="file-input" id={id}>
      <label
        className={`file-input__label ${
          failed ? 'failed' : ''
        }`}
        onClick={handleClick}
        htmlFor={`${id}_input`}
        {...(files.length === max && { disabled: 'disabled' })}
      >
        <span className="file-input__label-text">
          {label ? label : messages.contact_form.labels.attach_a_file}
        </span>

        <span className="file-input__button">
          {messages.contact_form.labels.browse}
        </span>

        <input
          className="file-input__input"
          type="file"
          onChange={handleChange}
          id={`${id}_input`}
          multiple={true}
          required={required}
        />
      </label>

      {error && <p className="file-input__error">{error}</p>}

      <ul className="file-input__files">
        {files.map((file, i) => {
          return (
            <SelectedFile
              key={`${file.name}-${i}`}
              file={file}
              index={i}
              onRemove={removeFile}
            />
          )
        })}
      </ul>
      {failed && <RequiredMessage />}
    </div>
  )
}

export default ContactFileInput
