import React from 'react'
import { NavOpenContext } from '../context/nav-open-context'

class MenuToggle extends React.Component {
  render() {
    const {
      target,
      label,
      className,
      activeClassName,
      toggleNav,
      navOpen,
      ...props
    } = this.props

    return (
      <NavOpenContext.Consumer>
        {({ navOpen, toggleNav }) => {
          const classes = [
            className,
            navOpen[target] ? activeClassName : '',
          ].join(' ')
          return (
            <button
              className={classes}
              {...props}
              onClick={toggleNav.bind({}, target)}
              aria-expanded={navOpen[target]}
            >
              {label}
            </button>
          )
        }}
      </NavOpenContext.Consumer>
    )
  }
}

export default MenuToggle
