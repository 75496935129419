import React, { Component, useContext } from 'react'
import ContactFormResource from './contact-form-resource'
import ContactTelephoneImage from './contact-telephone-image'
import ContactEmailImage from './contact-email-image'
import { ContactInput } from './contact-input'
import { ContactEmail } from './contact-email'
import { ContactSelect } from './contact-select'
import { ContactTextarea } from './contact-textarea'
import { AboutInfo } from './about-info'
import { scrollToElement } from 'crostamollica-components/helpers'
import ContactFileInput from './contact-file-input'
import * as EmailValidator from 'email-validator'
import { translations } from 'crostamollica-components/utils'
import { LanguageContext } from 'crostamollica-components/context'

const ContactInfo = () => {
  const { language } = useContext(LanguageContext)
  const messages = translations(language)
  return (
    <div
      className="contact-info"
      itemScope
      itemType="https://schema.org/Organization"
    >
      <div className="contact-info__title">{messages.contact_info.title}</div>

      <div className="contact-info__items">
        <span className="contact-info__item contact-info__item--email-address">
          <ContactEmailImage />
          <a itemProp="email" href="mailto: info@crostamollica.com">
            info@crostamollica.com
          </a>
        </span>

        <span className="contact-info__item contact-info__item--telephone-number">
          <ContactTelephoneImage />
          <a itemProp="telephone" href="tel:+442076275207">
            +44 (0) 20 7627 5207
          </a>

          <div className="contact-info__terms">Mon-Fri: 9am-5pm GMT</div>
        </span>
      </div>
    </div>
  )
}

class ContactForm extends Component {
  static contextType = LanguageContext

  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      phoneNumber: '',
      subject: '',
      choice: '',
      files: [],
      message: '',
      productName: '',
      productBoughtLocation: '',
      bestBeforeDate: '',
      batchCode: '',
      preferredAddress: '',
      invalidFields: [],
      formValid: false,
      formSubmitted: false,
      formSuccess: false,
      formFailed: true,
      posting: false,
      totalFail: false,
    }
  }

  checkFailure() {
    this.setState({
      formFailed: true,
      posting: false,
      formSubmitted: false,
      totalFail: true,
    })
  }

  scrollToSuccess() {
    scrollToElement(this.thankYouRef)
  }

  scrollToFail() {
    scrollToElement(this.contactFormRef)
  }

  sendMail = () => {
    // get the user data out of the state
    const {
      name,
      email,
      phoneNumber,
      subject,
      files,
      message,
      productName,
      productBoughtLocation,
      bestBeforeDate,
      batchCode,
      preferredAddress
    } = this.state

    // This matches the interface the lambda function expects
    const data = {
      name,
      email,
      phoneNumber,
      subject,
      files,
      message,
      productName,
      productBoughtLocation,
      bestBeforeDate,
      batchCode,
      preferredAddress
    }

    const resource = new ContactFormResource(data, this.props.language)

    const complete = async response => {
      const { status } = response

      if (status === 200) {
        this.setState(
          {
            formSuccess: true,
          },
          () => {
            this.scrollToSuccess()
          },
        )
      } else {
        this.checkFailure()
      }
    }

    resource.post().then(complete)
  }

  submit = () => {
    let required
    if(this.state.choice === '1') {
      required = ['name', 'email', 'phoneNumber', 'subject', 'productName', 'files', 'bestBeforeDate', 'batchCode', 'preferredAddress', 'message']
    }
    else {
      required = ['name', 'email', 'phoneNumber', 'subject', 'message']
    }

    const validatedFields = required
      .map(field => this.state[field] !== '')
      .filter(value => value === true)

    let invalidFields = required
      .map(field => (this.state[field] === '' ? field : null))
      .filter(value => value !== null)

    // check email format
    if (
      !EmailValidator.validate(this.state['email']) &&
      !invalidFields.includes('email')
    ) {
      invalidFields.push('email')
    }

    //check files format
    if(!invalidFields.includes('files') && required.includes('files') && this.state.files.length === 0) {
      invalidFields.push('files')
    }

    const validatedForm = validatedFields.length === required.length

    this.setState(
      {
        formSubmitted: true,
        posting: validatedForm,
        formValid: validatedForm,
        invalidFields: invalidFields,
      },
      () => {
        if (validatedForm === true && invalidFields.length === 0) {
          this.sendMail()
        } else {
          this.scrollToFail()
        }
      },
    )
  }

  isInvalidField(name) {
    return this.state.invalidFields.includes(name)
  }

  setThankYouRef = ref => (this.thankYouRef = ref)

  setContactFormRef = ref => (this.contactFormRef = ref)

  onVerify = token => {
    this.setToken(token)
  }

  renderForm() {
    let fileLabel = this.messages.contact_form.labels.attach_a_file;

    if(this.state.choice === '1') {
      fileLabel = this.messages.contact_form.labels.photo_of_product;
    }

    if(this.state.choice === '3') {
      fileLabel = this.messages.contact_form.labels.cv_upload;
    }
    return (
      <>
        <div className="contact-form" ref={this.setContactFormRef}>
          <h2>{this.messages.contact_form.title}</h2>
          <div>
            <form className="contact-form__form">

              <ContactInput
                failed={this.isInvalidField('name')}
                id="contact-name"
                label={`${this.messages.contact_form.labels.name}*`}
                onChange={data => this.setState({ name: data })}
                value={this.state.name}
              />

              <ContactEmail
                failed={this.isInvalidField('email')}
                id="contact-email"
                label={`${this.messages.contact_form.labels.email}*`}
                onChange={data => this.setState({ email: data })}
                value={this.state.email}
              />

              <ContactInput
                failed={this.isInvalidField('phoneNumber')}
                id="contact-number"
                label={`${this.messages.contact_form.labels.phone_number}*`}
                onChange={data => this.setState({ phoneNumber: data })}
                value={this.state.phoneNumber}
              />

              <ContactSelect
                failed={this.isInvalidField('subject')}
                id="contact-subject"
                label={`${this.messages.contact_form.labels.subject}*`}
                onChange={(data, option) => {
                  this.setState({ subject: data })
                  this.setState({ choice: option })
                }}
                value={this.state.subject}
                options={this.subjects}
              />

              {this.state.choice === '1' ?
                <ContactInput
                  failed={this.isInvalidField('productName')}
                  id="contact-name-of-product"
                  label={`${this.messages.contact_form.labels.name_of_product}*`}
                  onChange={data => this.setState({ productName: data })}
                  value={this.state.productName}
                />
              : ''}

              <ContactFileInput
                failed={this.isInvalidField('files')}
                id="contact-files"
                onChange={data => this.setState({ files: data })}
                files={this.state.files}
                label={fileLabel + `${this.state.choice === '1' ? '*' : ''}`}
                max={5}
                required={this.state.choice === '1'}
              />

              {this.state.choice === '1' ?
                <>
                  <ContactInput
                    failed={this.isInvalidField('productBoughtLocation')}
                    id="contact-product-bought-location"
                    label={`${this.messages.contact_form.labels.product_bought_location}`}
                    onChange={data => this.setState({ productBoughtLocation: data })}
                    value={this.state.productBoughtLocation}
                  />

                  <ContactInput
                    failed={this.isInvalidField('bestBeforeDate')}
                    id="contact-best-before-date"
                    label={`${this.messages.contact_form.labels.best_before_date}*`}
                    onChange={data => this.setState({ bestBeforeDate: data })}
                    value={this.state.bestBeforeDate}
                  />

                  <ContactInput
                    failed={this.isInvalidField('batchCode')}
                    id="contact-batch-code"
                    label={`${this.messages.contact_form.labels.batch_code}*`}
                    onChange={data => this.setState({ batchCode: data })}
                    value={this.state.batchCode}
                  />
                </>
              : ''}

              <ContactTextarea
                failed={this.isInvalidField('message')}
                id="contact-message"
                label={`${this.state.choice === '1' ? this.messages.contact_form.labels.product_message : this.messages.contact_form.labels.message}*`}
                onChange={data => this.setState({ message: data })}
                value={this.state.message}
              />

              {this.state.choice === '1' ?
                <>
                  <ContactTextarea
                    failed={this.isInvalidField('preferredAddress')}
                    id="contact-preferred-address"
                    label={`${this.messages.contact_form.labels.preferred_mailing_address}*`}
                    onChange={data => this.setState({ preferredAddress: data })}
                    value={this.state.preferredAddress}
                  />
                </>
              : ''}

              <AboutInfo />

              <button
                className={`contact-form__submit ${
                  this.state.posting && this.state.invalidFields.length === 0 ? 'contact-form__submit--loading' : ''
                }`}
                type="button"
                onClick={this.submit}
                disabled={this.state.posting && this.state.invalidFields.length === 0}
              >
                {this.state.posting && this.state.invalidFields.length === 0
                  ? this.messages.contact_form.labels.submit_sending
                  : this.messages.contact_form.labels.submit}
              </button>

              <ContactInfo />

            </form>
          </div>
        </div>
      </>
    )
  }

  renderThankYou() {
    return (
      <div className="contact-form" ref={this.setThankYouRef}>
        <div className="contact-form__thank-you">
          <div className="contact-form__thank-you__header">
            {this.messages.contact_form.success.title}
          </div>
          <div className="contact-form__thank-you__message">
            {this.messages.contact_form.success.text}
          </div>
        </div>
      </div>
    )
  }

  renderFail() {
    return (
      <div className="contact-form" ref={this.setThankYouRef}>
        <div className="contact-form__recaptcha-fail">
          <div className="contact-form__recaptcha-fail__header">
            {this.messages.contact_form.fail.title}
          </div>
          <div className="contact-form__recaptcha-fail__message">
            {this.messages.contact_form.fail.text}
          </div>

          <AboutInfo />

          <ContactInfo />
        </div>
      </div>
    )
  }

  render() {
    const { language } = this.context
    this.messages = translations(language)

    this.subjects = [
      this.messages.contact_form.subjects.general_enquiries,
      this.messages.contact_form.subjects.product_related,
      this.messages.contact_form.subjects.press_and_media,
      this.messages.contact_form.subjects.careers,
      this.messages.contact_form.subjects.other,
    ]

    if (this.state.formSuccess === true) {
      return this.renderThankYou()
    }

    if (this.state.formSuccess === false && this.state.totalFail === true) {
      return this.renderFail()
    }

    return this.renderForm()
  }
}

export default ContactForm
